import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import {
	Brands,
	Business,
	ContentLayout,
	Development,
	Management,
	OurMission,
	QuickBottomNavigationWrapper,
	Supervisory,
} from '../components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from '../stylesheets/pages/about-us.module.scss';
import { getLocalizedDataFromContentfulEdges } from '../utils';

const IndexPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "home" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
			contentfulOurMission {
				mobileBackgroundImage {
					fluid(maxWidth: 860, quality: 100) {
						...GatsbyContentfulFluid
					}
				}
				backgroundImage {
					fluid(maxWidth: 2550, quality: 100) {
						...GatsbyContentfulFluid
					}
				}
			}
		}
	`);
	const { node } = getLocalizedDataFromContentfulEdges(data.allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	const { backgroundImage, mobileBackgroundImage } = data.contentfulOurMission;
	return (
		<>
			<SEO title={node.title} description={node.description} lang={intl.locale}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<QuickBottomNavigationWrapper>
					<section className={styles.section}>
						<div className={styles.desktop}>
							<div className={styles.imgWrapperDesktop}>
								<Img fluid={backgroundImage.fluid} />
							</div>
							<ContentLayout>
								<OurMission />
							</ContentLayout>
						</div>
						<div className={styles.mobile}>
							<div className={styles.imgWrapperMobile}>
								<Img fluid={mobileBackgroundImage.fluid} />
							</div>
							<ContentLayout>
								<OurMission />
							</ContentLayout>
						</div>
					</section>
					<section className={styles.section}>
						<ContentLayout>
							<Business />
						</ContentLayout>
					</section>
					<section className={styles.section}>
						<ContentLayout>
							<Development />
						</ContentLayout>
					</section>
					<section className={styles.section}>
						<ContentLayout>
							<Brands />
						</ContentLayout>
					</section>
					<section className={styles.section}>
						<ContentLayout>
							<Management />
						</ContentLayout>
					</section>
					<section className={styles.section}>
						<ContentLayout>
							<Supervisory />
						</ContentLayout>
					</section>
				</QuickBottomNavigationWrapper>
			</Layout>
		</>
	);
};

export default IndexPage;
